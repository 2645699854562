<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-col :span="8">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item :label="$t('role')">
              <el-input clearable v-model="form.roleName"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="16" style="text-align: right">
          <el-button @click="searchEvent" class="search-button">{{
            $t("search")
          }}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{
            $t("clean")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        @sort-change="sort_change"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" align="center" label="序号" width="55">
        </el-table-column>
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column
          prop="roleId"
          align="center"
          width="105"
          label="角色编号"
        >
        </el-table-column>
        <el-table-column
          prop="roleName"
          min-width="168"
          align="center"
          label="角色名称"
        >
        </el-table-column>
        <el-table-column
          prop="roleKey"
          align="center"
          min-width="168"
          label="权限字符"
        >
        </el-table-column>
        <el-table-column
          prop="roleSort"
          align="center"
          min-width="115"
          label="显示顺序"
        >
          <!-- sortable="custom" -->
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              v-if="buttonAuth.includes('system:role:status')"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="changeStatus(scope.row)"
              active-value="0"
              inactive-value="1"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          width="210"
          align="center"
          label="创建时间"
        >
        </el-table-column>
        <el-table-column align="center" width="400px" :label="$t('option')">
          <template slot-scope="scope">
            <el-button
              class="default-button"
              v-if="buttonAuth.includes('system:role:edit')"
              @click="changeAddDialog(true, true, scope.row)"
              >{{ $t("edit") }}</el-button
            >
            <!-- <el-button
              class="default-button"
              v-if="buttonAuth.includes('system:role:data')"
              @click="changeDataDialog(scope.row)"
              >数据权限</el-button
            > -->
            <el-button
              class="default-button"
              v-if="buttonAuth.includes('system:role:remove')"
              @click="openDeleteDialog([scope.row.roleId])"
              >{{ $t("delete") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-content">
      <div class="">
        <el-button
          class="default-button"
          v-if="buttonAuth.includes('system:role:remove')"
          @click="openDeleteDialog(selectedArr.map((item) => item.roleId))"
          >{{ $t("delete") }}</el-button
        >
        <el-button
          class="default-button"
          v-if="buttonAuth.includes('system:role:add')"
          @click="changeAddDialog(true)"
          >{{ $t("add") }}</el-button
        >
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      @closeDialog="changeAddDialog"
    ></add-dialog>
    <!-- 数据权限弹窗 -->
    <data-dialog
      ref="dataDialog"
      :show="dataDialogVisible"
      @closeDialog="changeDataDialog"
    ></data-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog
      ref="confirmDialog"
      :Language="Language"
      :show="confirmDialog.visible"
      :type="confirmDialog.type"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import RoleSearchClass from "./roleSearchClass";
import apis from "@/apis";
import mixin from "@/mixins/index";
import confirmDialog from "@/components/confirmDialog.vue";
import addDialog from "./dialog/addDialog.vue";
import dataDialog from "./dialog/dataDialog.vue";

export default {
  components: {
    addDialog,
    dataDialog,
    confirmDialog,
  },
  mixins: [mixin],
  data() {
    return {
      form: new RoleSearchClass("form"), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      dataDialogVisible: false,
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
    };
  },
  computed: {
    ...mapState(["Language", "venueId", "merchantAccountDetailInfoDTO"]),
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      // 角色直接从小到大
      this.form = {
        ...this.form,
        merchantId: this.merchantAccountDetailInfoDTO.merchantId,
      };
      this.$http.get(apis.role_List, { params: this.form }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.form.total = res.data.total;
        }
      });
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new RoleSearchClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs["addDialog"].getDataQuery(data);
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent();
      }
      this.addDialog.visible = dialogStatus;
      this.$nextTick(() => {
        this.$refs["addDialog"].$refs.roleName.focus();
        this.$refs["addDialog"].$refs.form.clearValidate();
      });
    },
    /**
     * @function 修改数据权限
     */
    changeDataDialog(data) {
      if (this.dataDialogVisible) {
        this.dataDialogVisible = false;
        this.searchEvent();
      } else {
        this.dataDialogVisible = true;
        this.$refs.dataDialog.getDataQuery(data);
      }
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.ids = "";
        this.searchEvent();
      }
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http
        .post(apis.role_remove, {}, { params: this.confirmDialog.data })
        .then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog();
          }
        });
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      if (id.length === 0) {
        this.$message.error("请先选择数据");
        return;
      }
      this.confirmDialog.data.ids = id.join(",");
      this.changeConfirmDialog(true, 1);
    },
    /**
     * @function 修改启用状态
     * @param {Object} data 用户信息
     */
    changeStatus(data) {
      const form = {
        roleId: data.roleId,
        status: data.status,
      };
      this.$http.post(apis.roleStatus, form);
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
</style>
