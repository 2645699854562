<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? $t('edit') : $t('add')}${$t('role')}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      center
      width="50%"
    >
      <el-form ref="form" :model="form" label-width="88px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('role_name')" prop="roleName">
              <el-input
                clearable
                v-model="form.roleName"
                ref="roleName"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="权限字符" prop="roleKey">
              <el-input
                clearable
                v-model="form.roleKey"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="显示顺序" prop="roleSort">
              <el-input
                clearable
                v-model="form.roleSort"
                maxlength="5"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="状态" prop="status">
              <el-select v-model="form.status" style="width: 100%">
                <el-option :value="'0'" label="正常"></el-option>
                <el-option :value="'1'" label="禁用"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="数据权限" prop="dataPermission">
              <el-select v-model="form.dataPermission" style="width: 100%">
                <el-option
                  v-for="item in roleList"
                  :key="item.dataPermissionType"
                  :value="item.dataPermissionType"
                  :label="item.dataPermissionName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="拥有权限">
              <el-tree
                :data="permissions"
                :default-checked-keys="permissionsValue"
                ref="tree"
                node-key="key"
                :props="{
                  label: 'title',
                }"
                show-checkbox
                @check-change="handleCheckChange"
              >
              </el-tree>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{
          $t("sure")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";
import { mapState } from "vuex";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      permissions: [],
      pidSet: [],
      permissionsValue: [],
      roleList: [],
    };
  },
  created() {
    this.loadPermissions();
    // this.getDataPermissionOutputWhenUpdate();
  },
  computed: {
    ...mapState(["venueId", "merchantAccountDetailInfoDTO"]),
  },
  methods: {
    openDialog() {
      if (this.isEdit) {
        this.getDataPermissionOutputWhenUpdate();
      } else {
        this.getDataPermissionOutput();
      }
    },
    getDataPermissionOutput() {
      this.$http.post(apis.getDataPermissionOutput).then((res) => {
        if (res.data.code === 0) {
          this.roleList = res.data.rows;
          console.log(this.roleList);
        }
      });
    },
    getDataPermissionOutputWhenUpdate() {
      this.$http
        .get(
          `${apis.getDataPermissionOutputWhenUpdate}?dataPermission=${this.form.dataPermission}`,
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.roleList = res.data.rows;
            console.log(this.roleList);
            // this.closeDialog();
          }
        });
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.pidSet = [];
      if (this.$refs.tree) {
        this.$refs.tree.setCheckedKeys([]);
      }
      this.$refs.form.resetFields();
      this.$refs.form.clearValidate();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      console.log(this.roleList);
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.roleList.length === 1) {
            this.form = Object.assign({
              merchantId: this.merchantAccountDetailInfoDTO.merchantId,
              ...this.form,
            });
          }
          this.$http
            .post(this.isEdit ? apis.role_update : apis.role_save, this.form)
            .then((res) => {
              if (res.data.code === 0) {
                this.closeDialog();
              }
            });
        }
      });
      console.log(`我执行了点确定的业务需求`);
      // 调用关闭方法，不要再写一次
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      console.log(data);
      console.log("eeeeeeeeee");
      // 在这里获取回显的数据
      this.form = { ...data };
      this.$http
        .get(`${apis.permission_role}${data.roleId}`)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            const pidSet = new Set(
              res.data.map(m => m.parentId).filter(id => id > 0),
            );
            this.pidSet = pidSet;
            // 因为antd 树插件勾选父节点会导致所有子节点选中,所以过滤所有父节点
            this.permissionsValue = res.data
              .map(m => m.menuId)
              .filter(id => !pidSet.has(id));
          }
        })
        .then((res) => {
          console.log(res);
        });
    },
    loadPermissions() {
      this.$http.get(apis.permission_list).then((res) => {
        this.buildtree(res.data.rows, this.permissions, 0);
      });
    },
    buildtree(list, permissions, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          const child = {
            key: item.menuId,
            title: item.menuName,
            children: [],
          };
          this.buildtree(list, child.children, item.menuId);
          if (child.children.length === 0) {
            delete child.children;
          }
          permissions.push(child);
        }
      });
    },
    /**
     * @function 表单的树状选值触发的方法
     */
    handleCheckChange() {
      const menuIds = this.$refs.tree.getCheckedNodes(false, true);
      this.form.menuIds = menuIds.map(item => item.key);
    },
  },
};
</script>
